<template>
  <form @submit.prevent="saveConfig">
    <modal-card
      :title="`Database snapshots for ${database}`"
      :processing="processing"
    >
      <b-table
        :data="backups"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :default-sort="['backupfinished', 'desc']"
        :loading="processing"
      >
        <template slot-scope="props">
          <b-table-column field="backupfinished" label="Backup date" sortable>
            <span>{{
              $moment(new Date(props.row.backupfinished)).format(
                "Do MMM, YYYY @ HH:mm"
              )
            }}</span>
            <status-label
              v-if="props.row.pleasedeleteme"
              status="backup-deleted"
              class="has-margin-left-50"
            />
          </b-table-column>

          <b-table-column field="size" label="Size" width="120" sortable
            >~{{ humanFileSize(props.row.size) }}</b-table-column
          >

          <b-table-column label width="250" class="has-text-right">
            <span v-if="!props.row.pleasedeleteme">
              <a
                v-if="!props.row.deleting"
                class="has-text-dark"
                @click="downloadBackup(props.row)"
              >
                <u>Download</u>
                <span class="has-text-grey-light">|</span>
              </a>
              <a
                v-if="!props.row.deleting"
                class="has-text-dark"
                @click="restoreBackup(props.row)"
              >
                <u>Restore</u>
              </a>
              <span class="has-text-grey-light">|</span>
              <a class="has-text-danger" @click="deleteBackup(props.row)">
                <u>Delete</u>
              </a>
            </span>
            <a
              v-else
              class="has-text-dark"
              @click="undeleteSnapshot(props.row)"
            >
              <u>Undelete</u>
            </a>
          </b-table-column>
        </template>
      </b-table>
    </modal-card>
  </form>
</template>

<script>
import { humanFileSize } from "@src/services/fileutil";
import { downloadLink } from "@src/services/fileutil";
import { doc } from "@firebase/firestore";

export default {
  name: "DbBackupSnapshotModal",
  props: {
    siteId: {
      type: String,
      required: true
    },
    database: {
      type: String,
      required: true
    },
    backups: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      isPaginated: true,
      isPaginationSimple: true,
      currentPage: 1,
      perPage: 7
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    }
  },
  methods: {
    downloadBackup(backup) {
      this.processing = true;
      this.$store
        .dispatch("sites/backups/downloadBackup", {
          siteId: this.siteId,
          fqdn: this.site.fqdn,
          name: this.database,
          backupId: backup.id
        })
        .then(response => {
          downloadLink(response.url);

          this.$toast.open({
            message: "Download requested",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(() => {
          this.$toast.open({
            message: "There was a problem requesting this backup",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    restoreBackup(backup) {
      this.$store.dispatch("tasks/openAddTaskModal", {
        siteId: this.siteId,
        taskRef: doc(this.$firestore, "taskTypes", "restore-database"),
        default: `Please restore my '${backup.dbname}' database on ${this.site.fqdn}.`
      });
    },
    deleteBackup(snapshot) {
      const confirm = this.$confirm.open({
        parent: this,
        props: {
          autoClose: false,
          action: "Delete",
          message: `Are you sure you want to delete this backup ?`
        },
        canCancel: ["outside"],
        events: {
          confirmed: async () => {
            await this.deleteBackupConfirmed(snapshot);
            confirm.close();
          }
        }
      });
    },
    deleteBackupConfirmed(backup) {
      this.processing = true;
      const index = this.$_.findIndex(this.backups, ["id", backup.id]);
      return this.$store
        .dispatch("sites/backups/api", {
          type: "delete-database-backup",
          siteId: this.siteId,
          payload: {
            name: backup.dbname,
            backup_id: backup.id
          }
        })
        .then(() => {
          this.$set(this.backups[index], "pleasedeleteme", 1);
          this.$toast.open({
            message: "Snapshot marked for deletion",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(() => {
          this.$set(this.backups[index], "deleting", false);
          this.$toast.open({
            message: "Error deleting snapshot",
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => (this.processing = false));
    },
    undeleteSnapshot(snapshot) {
      this.processing = true;
      this.$store
        .dispatch("sites/backups/api", {
          type: "update-db-snapshot",
          siteId: this.siteId,
          payload: {
            snapshotId: snapshot.id,
            fqdn: this.site.fqdn,
            pleasedeleteme: 0,
            name: snapshot.dbname
          }
        })
        .then(() => {
          this.$set(snapshot, "pleasedeleteme", 0);
          this.$set(snapshot, "undeleting", false);
          this.$toast.open({
            message: "Snapshot undeleted",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(e => {
          this.$set(snapshot, "undeleting", false);
          this.$toast.open({
            message: e.message, //"Error deleting file snapshot",
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => (this.processing = false));
    },
    humanFileSize(size) {
      // Because we get the size in KB we need to multiply by 1024
      return humanFileSize(size * 1024);
    }
  }
};
</script>
